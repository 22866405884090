



























import { MarineInfo } from '@/models/MarineInfo';
import { AeroInfo} from '@/models/AeroInfo';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VDateField from '@/components/DateField.vue';

@Component({
    components: { VDateField },
})
export default class DynamicForm extends Vue {
    public datePickerShown: boolean = false;
    @Prop({ required: true }) formFields: any;
    @Prop({ default: () => {} }) value!: MarineInfo | AeroInfo;

    public get fields() {
        return Object.entries(this.value as any);
    }

    public get getRules() {
        return (index: number)=>this.formFields[index].rules || [];
    }

    public onFormChange(prop: string, value: string | Date) {
        this.$emit('input', { ...this.value, [prop]: value });
    }

    public updateInfo(prop: string, value: string | Date) {
        this.onFormChange(prop, value);
    }
}
