































import VDeclaration from '@/components/Declaration.vue';
import { Declaration } from '@/models/Declaration';
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { objectToFormData } from '@/utils/form.helper';
import router from '@/router';

@Component({
  components: {VDeclaration},
})
export default class DeclarationView extends Vue {
    public login!: string;
    public password!: string;
    public declaration: Declaration | null = null;
    public tab: number = 1;
    public skps: Array<any> = [];

    public created() {
        this.reset();
        axios.get("api/declaration/skps")
        .then((res)=>{this.skps = res.data});
    }

    public reset() {
        this.declaration = new Declaration();
    }
    
    public submit() {
        if(!(this.$refs.declaration as any).validate() || !this.declaration)
            return;
        if(this.tab===1){
            this.declaration.marineInfo = null;
        }
        else if(this.tab===2){
            this.declaration.aeroInfo = null;
        }
        let formData = objectToFormData({ ...this.declaration, type: this.tab }, null, null);
        const headers = { 'Content-Type': 'multipart/form-data' };
        axios.post('/api/declaration', formData, { headers }).then((res) => {
          (this as any).$bus.$emit('popup',{message:'Декларация добавлена', type:'success'});
          this.reset();
        }).catch((res)=>{
            (this as any).$bus.$emit('popup',{message:'Произошла ошибка', type:'error'});
        });
    }

        
    public toggleTab(tab: number) {
        this.tab = tab;
    }

}
