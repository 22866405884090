
































































import { Declaration } from '@/models/Declaration';
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import VDateField from '@/components/DateField.vue';
import VDynamicForm from '@/components/DynamicForm.vue';
import { AeroInfo, AeroInfoFields } from '@/models/AeroInfo';
import { MarineInfo, MarineInfoFields } from '@/models/MarineInfo';
import { rules } from '@/models/Rules';
import DatetimePicker from '@/components/DatetimePicker.vue';

@Component({
    components: { VDateField, VDynamicForm, DatetimePicker },
})
export default class DeclarationComponent extends Vue {
    private loading: boolean = true;
    private showError: boolean = false;
    public errorMessage: string = 'Ошибка';
    public infoVisible: boolean = false;
    public datePickerShown: boolean = false;

    @Prop({}) skps: any;
    @Prop({}) type: number | undefined;
    @Model('input', { default: () => new Declaration() }) readonly value!: Declaration;

    public get required(): any {
        return [rules.required];
    }

    public get aeroInfoFields(): any {
        return AeroInfoFields;
    }

    public get marineInfoFields(): any {
        return MarineInfoFields;
    }

    public get aeroInfoModel(): AeroInfo | null {
        return this.value.aeroInfo;
    }

    public set aeroInfoModel(nv: AeroInfo | null) {
        this.$emit('input', { ...this.value, aeroInfo: nv });
    }

    public get marineInfoModel(): MarineInfo | null {
        return this.value.marineInfo;
    }

    public set marineInfoModel(nv: MarineInfo | null) {
        this.$emit('input', { ...this.value, marineInfo: nv });
    }

    public validate() {
        return (this.$refs.form as any).validate();
    }

    public onFormChange(prop: string, value: string | Date) {
        this.$emit('input', { ...this.value, [prop]: value });
    }
}
