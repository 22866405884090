import { AeroInfo } from "./AeroInfo";
import { MarineInfo } from "./MarineInfo";

export class Declaration {
  constructor(
    public skp: string | null = null,
    public arrivalDate: string | null = null,
    public identifier: string | null = null,
    public attachment: File | null = null,
    public crewCount: number | null = 0,
    public passengersCount: number | null = 0,
    public infectionPresence: string | null = null,
    public aeroInfo: AeroInfo | null = new AeroInfo(),
    public marineInfo: MarineInfo | null = new MarineInfo(),
  ) {}
}
