export class MarineInfo {
  constructor(
    public port: string | null = null,
    public vesselName: string | null = null,
    public departurePort: string | null = null,
    public vesselFlag: string | null = null,
    public brutto: string | null = null,
    public date: Date | null = null,
    public imo: string | null = null,
    public goingTo: string | null = null,
    public captainFio: string | null = null,
    public agent: string | null = null,
    public beenOnContaminatedArea: string | null = null,
    public crewCount: string | null = null,
    public passengerCount: string | null = null,
    public sanitaryControlCerificate: string | null = null,
    public sccExpire: Date | null = null,
    public sccIssueDate: Date | null = null,
    public sccIssuePort: string | null = null,
    public reinspectionRequired: string | null = null,
    // public lastWaterAnalysisDate: Date | null = null,
    // public lastWaterAnalysisIssuePort: string | null = null,
    // public vesselAidkitCertificate: string | null = null,
    // public vesselAidkitCertificateDate: Date | null = null,
    // public vesselAidkitCertificateIssuePort: string | null = null,
    public isFumigatedCargo: string | null = null,
    public portCalls: string | null = null,
  ) { }
}

export let MarineInfoFields = {
  port: {label:'Представлена в порту', type: 'string'},
  vesselName: {label:'Название судна', type: 'string'},
  departurePort: {label:'Прибыло из', type: 'string'},
  vesselFlag: {label:'Флаг судна', type: 'string'},
  brutto: {label:'Брутто регистровый тоннаж', type: 'string'},
  date: {label:'Дата', type: 'date'},
  imo: {label:'ИМО', type: 'string'},
  goingTo: {label:'Направляется в', type: 'string'},
  captainFio: {label:'Фамилия капитана', type: 'string'},
  agent: {label:'Агент', type: 'string'},
  beenOnContaminatedArea: {label:'Заходило ли судно на зараженную территорию, определенную ВОЗ?', type: 'string'},
  // crewCount: {label:'Число членов экипажа на борту', type: 'string'},
  // passengerCount: {label:'Число пассажиров на борту', type: 'string'},
  sanitaryControlCerificate: {label:'Имеется ли на борту действительное Свидетельство об освобождении от санитарного контроля / Свидетельство о санитарном контроле?', type: 'enum', values: ['да','нет']},
  sccExpire: { label: 'Срок действия свидетельства об освобождении от санитарного контроля', type: 'date'},
  sccIssueDate: {label:'Дата выдачи сертификата', type: 'date'},
  sccIssuePort: {label:'Место выдачи сертификата', type: 'string'},
  reinspectionRequired: {label:'Требуется ли повторная инспекция', type: 'enum', values: ['да','нет']},
  // lastWaterAnalysisDate: {label:'Дата (Последний анализ питьевой воды)', type: 'date'},
  // lastWaterAnalysisIssuePort: {label:'Выдано в', type: 'string'},
  // vesselAidkitCertificate: {label:'Сертификат на судовую аптеку', type: 'string'},
  // vesselAidkitCertificateDate: {label:'Дата выдачи сертификата', type: 'date'},
  // vesselAidkitCertificateIssuePort: {label:'Порт выдачи сертификата', type: 'string'},
  // isFumigatedCargo: {label:'Перевозите ли Вы фумигированный груз?', type: 'string'},
  portCalls: { label: 'Посещенные порты за последний месяц', type: 'string' },
}