import {rules} from './Rules';

export class AeroInfo {
  constructor(
    public owner: string | null = null,
    public captainFio: string | null = null,
    public flightNumber: string | null = null,
    public date: Date | null = null,
    public departure: string | null = null,
    public arrival: string | null = null,
    public takenOnBoard: string | null = null,
    public wentOffBoard: string | null = null,
    public cargoTon: string | null = null,
  ) { }
}

export let AeroInfoFields = {
  owner: { label: 'Владелец', type: 'string' },
  captainFio: {label:'ФИО командира', type: 'string'},
  flightNumber: { label: 'Рейс', type: 'string' },
  date: { label: 'Дата', type: 'date' },
  departure: { label: 'Вылетающий из', type: 'string' },
  arrival: { label: 'Прибывающий в', type: 'string' },
  takenOnBoard: { label: 'Взято на борт', type: 'string' },
  wentOffBoard: { label: 'Сошло с борта', type: 'string' },
  cargoTon: { label: 'Груз (тонны)', type: 'string' },
}
