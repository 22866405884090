<template>
    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                    :value="parsed"
                    :label="label"
                    :disabled="disabled"
                    :loading="loading"
                    :dense="dense"
                    :error-messages="errorMessages"
                    :class="textFieldClass"
                    :prepend-icon="compPrependIcon"
                    readonly
                    :hide-details="hideDetails"
                    v-on="on"
                    :rules="rules"
                    :append-icon="(parsed)?'mdi-close':''"
                    @click:append="clear"
            ></v-text-field>
        </template>

        <template v-if="mode === 'datetime'">
            <v-date-picker v-if="!timemodal" v-model="date" v-bind="datePickerProps" @input="selectDate">
                <v-icon v-if="date" class="mt-n8 ml-2" @click="timemodal = true" color="primary">mdi-clock-outline
                </v-icon>
            </v-date-picker>
            <v-time-picker v-bind="timePickerProps" @click:minute="select" v-if="timemodal" v-model="time"
                           format="24hr">
                <v-icon class="mt-n8 ml-2" @click="timemodal = false" color="primary">mdi-calendar</v-icon>
            </v-time-picker>
        </template>
        <template v-else-if="mode === 'date'">
            <v-date-picker v-model="date" v-bind="datePickerProps" @input="selectDate"/>
        </template>
        <template v-else-if="mode === 'time'">
            <v-time-picker @click:minute="selectDate" v-bind="timePickerProps" v-model="time" format="24hr"/>
        </template>
    </v-menu>
</template>

<script>
    export default {
        name: "DatetimePicker",
        props: {
            rules: Array,
            label: {
                type: String,
                default: 'Дата'
            },
            prefix: {
                type: String,
                default: ''
            },
            value: [String, Object, Date],
            disabled: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            dense: {
                type: Boolean
            },
            hideDetails: {
                type: Boolean
            },
            mode: {
                mode: String,
                default: 'datetime',
                validator: function (value) {
                    return ['date', 'time', 'datetime'].indexOf(value) !== -1
                }
            },
            errorMessages: {
                type: [String, Array],
                default: null
            },
            datePickerProps: {
                type: Object
            },
            timePickerProps: {
                type: Object
            },
            textFieldClass: {
                type: String
            },
            prependIcon: {
                type: String
            }
        },
        data: vm => ({
            menu: false,
            timemodal: false,
            date: null,
            time: null,
        }),
        computed: {
            parsed() {
                if (this.mode === 'datetime') {
                    if (this.date) {
                        let date = this.$moment(this.date).format('DD.MM.YYYY');
                        // let time = (this.time) ? this.time : '00:00';
                        let time = (this.time) ? this.$moment(this.time, 'HH:mm:ss').format('HH:mm') : '00:00';
                        return `${this.prefix} ${date} ${time}`;
                    }
                } else if (this.mode === 'date') {
                    if (this.date) {
                        let date = this.$moment(this.date).format('DD.MM.YYYY');
                        return `${this.prefix} ${date}`;
                    }
                } else if (this.mode === 'time') {
                    if (this.time) {
                        let time = (this.time) ? this.$moment(this.time, 'HH:mm:ss').format('HH:mm') : '00:00';
                        return `${this.prefix} ${time}`;
                    }
                }
                return null
            },
            compPrependIcon() {
                if(this.prependIcon) {
                    return this.prependIcon
                } else {
                   return this.mode === 'time' ? 'mdi-clock-outline' : 'mdi-calendar-outline'
                }
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    if (val) {
                        if (this.mode === 'datetime') {
                            let dt = this.$moment(val);
                            this.date = dt.format('YYYY-MM-DD');
                            this.time = dt.format('HH:mm');
                        } else if (this.mode === 'date') {
                            let dt = this.$moment(val);
                            this.date = dt.format('YYYY-MM-DD');
                        } else if (this.mode === 'time') {
                            this.time = this.$moment(val, 'HH:mm:ss').format('HH:mm')
                        }
                    } else {
                        this.date = null;
                        this.time = null;
                    }
                }
            }
        },
        methods: {
            clear() {
                this.date = null;
                this.time = null;
                this.$emit('input', null);
            },
            close() {
                this.timemodal = false;
                this.menu = false;
            },
            select() {
                let date = this.$moment(this.date).format('YYYY-MM-DD');
                let time = (this.time) ? this.$moment(this.time, 'HH:mm:ss').format('HH:mm') : '00:00';
                this.$emit('input', `${date} ${time}:00`);
                this.close();
            },
            selectDate() {
                if (this.mode === 'datetime') {
                    let date = this.$moment(this.date).format('YYYY-MM-DD');
                    let time = (this.time) ? this.$moment(this.time, 'HH:mm:ss').format('HH:mm') : '00:00';
                    this.$emit('input', `${date} ${time}:00`);
                    this.timemodal = true;
                } else if (this.mode === 'date') {
                    let date = this.$moment(this.date).format('YYYY-MM-DD');
                    this.$emit('input', `${date}`);
                    this.close();
                } else if (this.mode === 'time') {
                    let time = (this.time) ? this.$moment(this.time, 'HH:mm:ss').format('HH:mm') : '00:00';
                    this.$emit('input', `${time}:00`);
                    // this.$emit('input', this.$moment(time, 'HH:mm:ss').format('HH:mm:ss'));
                    this.close();
                }
            }
        },
    }
</script>

<style scoped>

</style>
