












import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  components: {},
})
export default class DateFieldComponent extends Vue {
    public datePickerShown: boolean = false;

    @Prop({default: null}) public value!: string | null;
    @Prop({}) public rules: any;
    @Prop({default: 'DD.MM.YYYY'}) public dateFormat!: string;
    @Prop({default: 'Введите дату'}) public label!: string;


    public onInputChange(value: string) {
        this.$emit('input', value);
        this.$emit('change', value);
        if(typeof value === 'string') {
            let date = moment(value, "YYYY-MM-DD");
            let format = date.format(this.dateFormat);
            this.$emit('parsed', format);
        }
    }
}
